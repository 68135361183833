import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import assets from "assets";
import SuccessState from "components/SuccessState";
import * as S from "./Unsubscribe.styles";

interface IProps {}

export const Unsubscribe = (_: IProps) => {
  // Render
  return (
    <S.Wrap>
      <S.InnerWrap>
        <SuccessState
          image={assets.IMG_MAILED}
          title={getLocalizedText("insurance.unsubscribe_success.title")}
          info={
            <span
              dangerouslySetInnerHTML={{
                __html: getLocalizedText("insurance.unsubscribe_success.info"),
              }}
            />
          }
        />
      </S.InnerWrap>
    </S.Wrap>
  );
};
